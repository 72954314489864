import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { string } from 'prop-types';
import { RightOutlined } from '@ant-design/icons';

import CaliceoLink from 'components/buttons/CaliceoLink';

import useWindowWidth from 'hooks/use-window-width';
import './complexPushBlock.scss';

import { centreComplexPushBlock } from 'utils/constants';

const ComplexPushBlock = (props) => {
  const { selectedCenter } = props;

  const COMPLEX_PUSH_BLOCK_QUERY = gql`
    query ComplexPushBlockQuery($blockId: String!){
      blockContentById(id: $blockId) {
        ... on BlockContentPushButton {
          entityId
          entityLabel
          fieldActiveBloc
          fieldTitre
          fieldDescription {
            processed
          }
          fieldLien {
            title
            url {
              path
            }
          }
          fieldIcone {
            entity {
              ... on File {
                filename
                url
              }
            }
          }
        }
      }
    }
  `;

  const [getComplexPushBlock, complexPushBlockRes] = useLazyQuery(COMPLEX_PUSH_BLOCK_QUERY);

  useEffect(() => {
    if (selectedCenter) {
      getComplexPushBlock({
        variables: {
          blockId: centreComplexPushBlock[selectedCenter],
        },
      });
    }
  }, [selectedCenter]);

  const blockData = complexPushBlockRes.data?.blockContentById;

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  if (!complexPushBlockRes.data) {
    return null;
  }

  if (!blockData.fieldActiveBloc) {
    return null;
  }

  if (windowWidth < 769) {
    return (
      <div className="container">
        <div className="complex-push-block--mobile">
          <div className="complex-push-block--body">
            <img className="complex-push-block--image" src={blockData.fieldIcone.entity.url} alt={blockData.fieldIcone.entity.title} />
            <div className="complex-push-block--texts">
              <h1 className="complex-push-block--title">{blockData.fieldTitre}</h1>
              <div className="complex-push-block--description" dangerouslySetInnerHTML={{ __html: blockData.fieldDescription.processed }} />
            </div>
            <Link to={blockData.fieldLien.url.path} className="complex-push-block--mobile-link">
              <RightOutlined />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="complex-push-block--wrapper">
      <div className="container">
        <div className="complex-push-block--body">
          <img className="complex-push-block--image" src={blockData.fieldIcone.entity.url} alt={blockData.fieldIcone.entity.title} />
          <div className="complex-push-block--texts">
            <h1 className="complex-push-block--title">{blockData.fieldTitre}</h1>
            <div className="complex-push-block--description" dangerouslySetInnerHTML={{ __html: blockData.fieldDescription.processed }} />
          </div>
          <CaliceoLink
            primaryButton={false}
            classNames="secondary-caliceo--button--inverted complex-push-block--link"
            url={blockData.fieldLien.url.path}
            title={blockData.fieldLien.title}
            iconFill="#1f324d"
          />
        </div>
      </div>
    </div>
  );
};

ComplexPushBlock.defaultProps = {
  selectedCenter: 'home-group',
};

ComplexPushBlock.propTypes = {
  selectedCenter: string,
};

export default ComplexPushBlock;
