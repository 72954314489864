import React from 'react';
import YouTube from 'react-youtube';

import CaliceoLink from 'components/buttons/CaliceoLink';

import getYoutubeVideoId from 'utils/getYoutubeVideoId';
import PinIcon from 'images/icons/pin.png';

import CaliceoLogo from 'images/caliceo-menu-logo.svg'

const CenterHeaderSlide = (props) => {
  const { data, centerName } = props;

  const opts = {
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      autoplaySpeed: 10000,
      controls: 0,
      showInfo: 0,
      rel: 0,
      playsinline: 1,
    },
  };

  const onReady = (e) => {
    e.target.playVideo();
  };

  const onEnd = (e) => {
    e.target.playVideo();
  };

  return (
    <div className="center-hero-slide">
      {data?.fieldMiseAvantVisuel[0]?.entity?.fieldMediaOembedVideo ? (
        <div className="video-background">
          <div className="video-foreground">
            <YouTube onEnd={onEnd} videoId={getYoutubeVideoId(data.fieldMiseAvantVisuel[0].entity.fieldMediaOembedVideo)} opts={opts} onReady={onReady} />
          </div>
        </div>
      ) : (
        <img className="center-hero-slide--image" src={data?.fieldMiseAvantVisuel[0]?.entity.fieldMediaImage.entity.url} alt={data?.fieldMiseAvantTitre} />
      )}
      <div className="container">
        <div className="center-header-slide--content">
          <img src={CaliceoLogo} className="hero-content--logo" alt="Caliceo" />
          <div className="center-header-name">
            <img src={PinIcon} alt="Center pin" />
            {centerName}
          </div>
          <h1 className="center-header-slide--title">{data?.fieldMiseAvantTitre}</h1>
          <div className="center-header-slide--desc" dangerouslySetInnerHTML={{ __html: data?.fieldMiseAvantTexte?.processed }} />
          <CaliceoLink
            url={data?.fieldMiseAvantLien?.url.path}
            title={data?.fieldMiseAvantLien?.title}
            primaryButton
            classNames="center-header-slide--button"
          />
        </div>
      </div>
    </div>
  );
};

export default CenterHeaderSlide;
