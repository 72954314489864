import gql from 'graphql-tag';

const CENTER_PRESENTATION_QUERY = gql`
  query CenterPresentation($centerId: [String]) {
    nodeQuery(filter: {conditions: [{field: "field_centreid", value: $centerId}]}) {
      entities {
        entityId
        entityBundle
        ... on NodeCentre {
          fieldDomainAccess {
            entity {
              entityId
            }
          }
          fieldPresentationTitre
          fieldPresentationSoustitre
          fieldPresentationDescription {
            processed
          }
          fieldPresentationVisuel {
            entity {
              ... on MediaImage {
                fieldMediaImage {
                  title
                  url
                }
              }
            }
          }
          fieldPresentationCentreLink {
            entity {
              entityUrl {
                path
              }
            }
          }
          queryFieldPresentationCentreLink {
            entities {
              entityUrl {
                path
              }
            }
          }
          fieldPresentationTarifsLink {
            entity {
              entityUrl {
                path
              }
            }
          }
          fieldReseauxSociaux {
            title
            url {
              path
            }
          }
          fieldTemperatureEau
          fieldAfficherTemperature
          fieldAfficherJauge
          fieldUrlJaugeFrequentation {
            url {
              path
            }
          }
        }
      }
    }
  }`;

//   const presentation = caliceo.nodeQuery.entities[0];

//   return {
//     title: presentation.fieldPresentationTitre,
//     subtitle: presentation.fieldPresentationSoustitre,
//     desc: presentation.fieldPresentationDescription.processed,
//     ourPricesLink: presentation.fieldPresentationTarifsLink.entity.entityUrl.path,
//     ourCentersLink: presentation.fieldPresentationCentreLink.entity.entityUrl.path,
//     imageUrl: presentation.fieldPresentationVisuel.entity.fieldMediaImage.url,
//     socialLinks: presentation.fieldReseauxSociaux,
//   };
// };

export default CENTER_PRESENTATION_QUERY;
