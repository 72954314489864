import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import GET_CENTER_EVENTS_BY_ID from 'hooks/use-center-events-content';

import { centreEventsBlockIds } from 'utils/constants';
import userIsLoggedIn from 'utils/checkLoggedIn';
import CaliceoLink from 'components/buttons/CaliceoLink';
import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';

import qs from 'qs';
import API from 'api';

import CenterEventsSection from './CenterEventsSection';
import './centerEvents.scss';
import UserCardUnauthorized from './UserCard/UserCardUnauthorized';
import UserCardAuthorized from './UserCard/UserCardAuthorized';

const CenterEvents = (props) => {
  const { selectedCenter } = props;
  const cookies = new Cookies();

  const [eventNodeIds, setEventNodeIds] = useState([]);
  const [eventUrls, setEventUrls] = useState([]);
  const [getEvents, eventsRes] = useLazyQuery(GET_CENTER_EVENTS_BY_ID);

  const urlFragments = props.location?.pathname.split('/');


  const getEventNodeIds = (nodeUrl) => {
    const urlFragments = nodeUrl.split('/');
    return urlFragments[1];

    
    
  };


  useEffect(() => {
    if(selectedCenter !== "") {
      const postValues = {
      session: cookies.get(centerCookieIds[props.selectedCenter]?.cookieName),
      id: centreEventsBlockIds[selectedCenter],
    };
    const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
    API.get(`/centre-events-block?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
        if (res.data.status === 1) {
          let tabId = [];
          if(res.data?.responseData?.entities) {
            res.data?.responseData?.entities.forEach(e => {
              if(!e.fieldDateFin) {
                tabId.push(e.nid);
              }else {
                const today = new Date();
                const d_fin_mess = new Date(e.fieldDateFin);
                if(d_fin_mess >= today) {
                  tabId.push(e.nid);
                }
              }
            });
            setEventNodeIds(tabId);
          }
          
        }
      });
    }
    
  }, [selectedCenter]);

  useEffect(() => {
    const slicedNodeIds = eventUrls.map((nodeId) => getEventNodeIds(nodeId.uri));
    //setEventNodeIds(slicedNodeIds);
  }, [eventUrls]);

  useEffect(() => {
    if (selectedCenter) {
      getEvents({
        variables: {
          centerId: centreEventsBlockIds[selectedCenter],
        },
      });
    }
  }, [selectedCenter]);

  useEffect(() => {
    if (eventsRes?.data) {
      setEventUrls(eventsRes?.data.blockContentById.fieldContenuLien);
    }
  }, [eventsRes?.data?.blockContentById]);

  if (!eventsRes?.data) {
    return null;
  }


  return (
    <div className="center-events--section">
      <div className="container">
        <div className="center-events--row">
          <div className="custom-user--block">
            {userIsLoggedIn(selectedCenter) ? (
              <UserCardAuthorized selectedCenter={selectedCenter} />
            ) : (
              <UserCardUnauthorized selectedCenter={selectedCenter} />
            )}
          </div>
          <CenterEventsSection
            eventNodeIds={eventNodeIds}
            selectedCenter={props.selectedCenter}
            title={eventsRes?.data.blockContentById.fieldTitre}
            allEventsLink={eventsRes?.data.blockContentById.fieldLien?.url?.path}
            allEventsTitle={eventsRes?.data.blockContentById.fieldLien?.title}
          />
        </div>
      </div>
    </div>
  );
};

export default CenterEvents;
