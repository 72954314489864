import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { navigate } from 'gatsby';
import './guideGiftModal.scss';
import RightArrow from '../../icon-components/RightArrow';
import forwardToCenter from 'utils/forwardToCenter';
import { getCenterName, getHostname } from 'utils/location';
import { Link } from 'gatsby';

const GuideGiftModal = (props) => {
  const {
  giftModalShown, setGiftModalShown, selectedCenter, displayedCenters, centers
  } = props;

  if (!giftModalShown) {
  return null;
  }
  const centerName = getCenterName(props.location);
  const hostname = getHostname(props.location);

  const [price, setPrice] = useState(null);
  const [typeO, setTypeO] = useState(0);
  const [whoSel, setWhoSel] = useState(0);
  const [minWidth, setMinWidth] = useState(0);
  const [form] = Form.useForm();

  var priceInput;
  const validateMessages = {
  required: 'This field is required',
  };
  /**
   * Field Items Select
   */
  const who = [
  {name: "Tous", id: 0},
  {name: "Un(e) ami(e)", id: 29},
  {name: "Mes parents", id: 30},
  {name: "Mes enfants", id: 31},
  {name: "Ma conjointe", id: 32},
  {name: "Mon conjoint", id: 33},
  {name: "Un(e) collègue", id: 34},
  ];
  const giftTypes = [
  {name: "Sans préférence", id: 0},
  {name: "Bien-être", id: 26},
  {name: "Forme", id: 27},
  {name: "Original", id: 35},
  {name: "Santé", id: 28},
  ];
  const prices = [
  10, 20, 50, 100,
  ];
  // const getOfferPrice = (quantity, price) => quantity * price;

  useEffect(() => {
  fixWidthInputs();
  }, []);

  const changePrice = (e) => {
  e.preventDefault();
  let val = e.target?.dataset?.value;
  setPrice(parseInt(val));
  priceInput.input.value = parseInt(val);
  };
  const onSubmit = (values) => {
  const url = `/offers/${typeO}/${whoSel}/${price}`;
  //const url = `/offers/${values['gift-type']}/${typeO}/${price}`;
  let center = values.center;
  if (!center && selectedCenter) {
  center = selectedCenter;
  }
  if (`centre-${centerName}` === center) {
  navigate(url);
  } else {
  forwardToCenter(centers, center, hostname, url);
  }
  };
  
  const handleChangeType = (event) => {
  setTypeO(event);
  }

  const fixWidthInputs = () => {
  const selector = document.querySelectorAll('.gift-modal--form--field');
  selector.forEach((elem) => {
  if (minWidth < elem.offsetWidth) {
  setMinWidth(elem.offsetWidth);
  }
  });
  };
  return (
  <Modal
  width={850}
  className="one-time-modal gift-modal--cont"
  visible={giftModalShown}
  footer={null}
  onCancel={() => setGiftModalShown(false)}
  >
  <div className="gift-modal--in">
  <div className="gift-modal--title--cont">
    <div className="gift-modal--title--in">
    <h3 className="one-time-modal--title gift-modal--title--text">
    Dites-nous en plus
    </h3>
    </div>
  </div>
  <div className="gift-modal--sub-title--cont">
    <div className="gift-modal--sub-title--in">
    <p className="gift-modal--sub-title--text">
    En remplissant le formulaire ci-dessous, vous accéderez aux offres Calicéo personnalisées pour vous.
    </p>
    </div>
  </div>
  <div className="gift-modal--form--cont">
    <Form
    initialValues={{
    center: selectedCenter || 'sélectionnez votre centre',
    who: who[0].id,
    'gift-type': giftTypes[0].id,
    }}
    validateMessages={validateMessages}
    layout="horizontal"
    form={form}
    name="control-hooks"
    onFinish={onSubmit}
    >
    <div className="gift-modal--form--in">
    {/* <div className="gift-modal--form--who--cont">
    <div className="gift-modal--form--who--in">
      <Form.Item
      name="who"
      label="C'est pour"
      rules={[{ required: true, message: 'Veuillez sélectionner le destinataire' }]}
      >
      <Select
      size="large"
      dropdownAlign={{ offset: [0, 0] }}
      dropdownClassName="gift-modal--form--who--field--dropdown"
      className="gift-modal--form--who--field gift-modal--form--field"
      style={{ minWidth: minWidth }}
      >
      {who.map((option) => (
      <Select.Option key={`who-${option.id}`} value={option.id}>
        {option.name}
      </Select.Option>
      ))}
      </Select>
      </Form.Item>
    </div>
    </div> */}
    <div className="gift-modal--form--budget--cont">
    <div className="gift-modal--form--budget--in">
      <div className="ant-row ant-form-item">
      <div className="ant-col ant-form-item-label">
      <label htmlFor="control-hooks_who" className=""
         title="J'ai un budget de">J'ai un budget de</label>
      </div>
      <div className="ant-col ant-form-item-control">
      <div className="ant-form-item-control-input">
      <div className="ant-form-item-control-input-content">

        <Input
        style={{ minWidth: (minWidth + 20) }}
        name="price"
        type="number"
        max="500"
        inputMode="numeric"
        className="gift-modal--form--budget--field gift-modal--form--field"
        placeholder="Saisissez votre montant"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        ref={(input) => priceInput = input}
        />
      </div>
      </div>
      </div>
      </div>
      <div className="gift-modal--form--budget--actions--cont">
      <div className="gift-modal--form--budget--actions--in">
      {prices.map((price) => (
      <div className="gift-modal--form--budget--actions--price--cont" key={`price-${price}`}>
        <div className="gift-modal--form--budget--actions--price--in">
        <button data-value={price} onClick={changePrice} className="gift-modal--form--budget--actions--price--btn">{price}€</button>
        </div>
      </div>
      ))}
      </div>
      </div>
    </div>
    </div>
    <div className="gift-modal--form--gift-type--cont">
    <div className="gift-modal--form--gift-type--in">
      <Form.Item
      name="gift-type"
      label="Pour un cadeau plutôt"
      rules={[{ required: false, message: 'Veuillez sélectionner le type de cadeau' }]}
      >
      <Select
      className="gift-modal--form--gift-type--field gift-modal--form--field"
      size="large"
      dropdownAlign={{ offset: [0, 0] }}
      style={{ minWidth: (minWidth + 20) }}
      dropdownClassName="gift-modal--form--gift-type--field--dropdown"
      onChange={handleChangeType}
      >
      {giftTypes.map((option) => (
      <Select.Option  key={`gift-type-${option.id}`} value={option.id} >
        {option.name}
      </Select.Option>
      ))}
      </Select>
      </Form.Item>
    </div>
    </div>
    <div className="gift-modal--form--center--cont">
    <div className="gift-modal--form--center--in">
      <Form.Item
      label="Dans le centre de"
      rules={[{ required: false, message: 'Veuillez sélectionner un centre' }]}
      name="center"
      >
      <Select
      size="large"
      value={selectedCenter || 'sélectionnez votre centre'}
      className="gift-modal--form--center--field gift-modal--form--field"
      dropdownAlign={{ offset: [0, 0] }}
      style={{ minWidth: (minWidth + 20) }}
      dropdownClassName="gift-modal--form--center--field--dropdown"
      >
      {displayedCenters.map((center) => (
      <Select.Option key={`center-${center.centerId}`} value={center.centerId}>{center.name}</Select.Option>
      ))}
      </Select>
      </Form.Item>
    </div>
    </div>
    <div className="gift-modal--form--actions--cont">
    <div className="gift-modal--form--actions--in">
      <Form.Item>
      <button type="submit" className="primary-caliceo--button gift-modal--form--actions--btn">Rechercher mon cadeau <RightArrow/></button>
      </Form.Item>
    </div>
    </div>
    <div className="gift-modal--form--actions--cont">
    <div className="gift-modal--form--actions--in">
      <Form.Item>           
      <Link to="/offers" className="m-auto secondary-caliceo--button width-200 flex-direction-column">
      Toutes nos offres                    
      </Link>
      </Form.Item>
    </div>
    </div>
    </div>
    </Form>
  </div>
  </div>
  </Modal>
  );
};

export default GuideGiftModal;
