import gql from 'graphql-tag';

const CENTER_ONE_TIME_MESSAGE_MODAL_QUERY = gql`
  query OneTimeMessageModal($oneTimeMessageBlockConditions: [EntityQueryFilterConditionInput]){
    blockContentQuery(
      filter: {
        conditions: $oneTimeMessageBlockConditions
      }
    ) {
      entities {
        ... on BlockContentPopin {
          entityId
          entityLabel
          fieldActiveBloc
          fieldTitre
          fieldDescription {
            processed
          }
          fieldLien {
            title
            url {
              path
            }
          }
          fieldImage {
            entity {
              ... on MediaImage {
                fieldMediaImage {
                  title
                  url
                }
              }
            }
          }
          fieldDateDebut {
            date
          }
          fieldDateFin {
            date
          }
        }
      }
    }
  }
`;

export default CENTER_ONE_TIME_MESSAGE_MODAL_QUERY;
