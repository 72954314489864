import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import gql from 'graphql-tag';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Research from 'components/home-group/research/ResearchModule';
import { getCenterById } from 'utils/getCentersBy';
import CenterHeaderSlide from './CenterHeaderSlide';
import './centerHeader.scss';

const CenterHeader = (props) => {
  const { selectedCenter, centers, location} = props;

  const CENTER_HEADER_QUERY = gql`
  query CenterHeaderQuery($centerNid: String!){
    nodeById(id: $centerNid) {
      entityId
      entityBundle
      ... on NodeCentre {
        fieldDomainAccess {
          entity {
            entityId
          }
        }
        fieldMiseAvant {
          entity {
            ... on ParagraphMiseAvant {
              fieldMiseAvantTitre
              fieldMiseAvantTexte {
                processed
              }
              fieldMiseAvantVisuel {
                entity {
                  ... on MediaImage {
                    fieldMediaImage {
                      entity {
                        filename
                        url
                      }
                    }
                  }
                  ... on MediaRemoteVideo {
                    fieldMediaOembedVideo
                  }
                  ... on MediaVideo {
                    fieldMediaVideoFile {
                      entity {
                        filename
                        url
                      }
                    }
                  }
                }
              }
              fieldMiseAvantLien {
                title
                url {
                  path
                }
              }
              fieldMiseAvantDateDebut {
                date
              }
              fieldMiseAvantDateFin {
                date
              }
            }
          }
        }
      }
    }
  }
`;

  const [centerObject, setCenterObject] = useState(null);

  useEffect(() => {
    setCenterObject(getCenterById(centers, selectedCenter));
  }, [selectedCenter, centers]);
  
  //console.log(centers)

  const [getCenterHeaderData, { data, loading, error }] = useLazyQuery(CENTER_HEADER_QUERY, {
    returnPartialData: true,
  });

  useEffect(() => {
    if (centerObject && !data) {
      getCenterHeaderData({
        variables: {
          centerNid: `${centerObject.nid}`,
        },
      });
    }
  }, [centerObject, data]);



  const settings = {
    dots: true,
    infinite: true,
    autoplay:true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    pauseOnHover:false,
  };

  return (
    <div className="center-hero--section">
      <Slider {...settings} className="center-hero-slider">
        {data?.nodeById?.fieldMiseAvant.map(({ entity }) => {
          if(moment(entity.fieldMiseAvantDateFin.date,'YYYY-MM-DD hh:mm:ss a').isAfter(moment())) {
            return (
              <CenterHeaderSlide key={entity.fieldMiseAvantTitre} data={entity} centerName={centerObject?.name} />
            )
          }
        })}
      </Slider>
      <div className="container position-relative">
        <Research location={location} selectedCenter={props.selectedCenter} />
      </div>
    </div>
  );
};

export default CenterHeader;
