import React, { useState, useEffect } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/react-hooks';

import CaliceoButton from 'components/buttons/CaliceoButton';

import useWindowWidth from 'hooks/use-window-width';

const OfferSlide = (props) => {
  const { offer, isPromo, handleOfferClick } = props;
  const [windowWidth, setWindowWidth] = useState(0);

  const OFFER_QUERY = gql`
  query OfferQuery($offerId: String!){
    nodeById(id: $offerId) {
      ... on NodeOffre {
        nid
        title
        status
        fieldAquaoOid
        fieldDomainAccess {
          entity {
            entityId
          }
        }
        fieldAccrocheTexte {
          processed
        }
        path {
          alias
        }
        fieldAccrocheFondImage {
          entity {
            ... on MediaImage {
              fieldMediaImage {
                title
                derivative(style: LARGE) {
                  url
                }
              }
            }
          }
        }
      }
    }
  }`;

  const { data, loading, error } = useQuery(OFFER_QUERY, {
    variables: {
      offerId: offer.offerNid,
    },
  });

  useWindowWidth({ setWindowWidth });

  if (!data?.nodeById) {
    return null;
  }

  if (windowWidth < 769) {
    return (
      <>
        <div className="slider-offer-mobile">
          <img className="slider-offer-mobile--image" src={data?.nodeById.fieldAccrocheFondImage?.entity?.fieldMediaImage.derivative.url} alt={data?.nodeById.title} />
          {isPromo && (
            <div className="slider-offer--promo-badge">
              <span>en promo</span>
            </div>
          )}
          <h3 className="slider-offer-mobile--title">{data?.nodeById.title}</h3>
        </div>
        <div className="slider-offer-mobile--body">
          <div className="slider-offer-mobile--description" dangerouslySetInnerHTML={{ __html: data?.nodeById.fieldAccrocheTexte?.processed }} />
          <CaliceoButton
            primaryButton
            onClick={(e) => handleOfferClick(e, offer.itemAquaoOid, data.nodeById)}
            classNames={`${isPromo ? 'promoted-offer--button' : ''}`}
            title="Voir l'offre"
          />
        </div>
      </>
    );
  }

  return (
    <div className="slider-offer">
      <img className="slider-offer--image" src={data?.nodeById.fieldAccrocheFondImage?.entity?.fieldMediaImage?.derivative?.url} alt={data?.nodeById?.title} />
      {isPromo && (
        <div className="slider-offer--promo-badge">
          <span>en promo</span>
        </div>
      )}
      <div className="slider-offer--body">
        <h3 className="slider-offer--title">{data?.nodeById.title}</h3>
        <div className="slider-offer--description" dangerouslySetInnerHTML={{ __html: data?.nodeById.fieldAccrocheTexte?.processed }} />
        <CaliceoButton
          primaryButton
          onClick={(e) => handleOfferClick(e, offer.itemAquaoOid, data.nodeById)}
          classNames={`${isPromo ? 'promoted-offer--button' : ''}`}
          title="Voir l'offre"
        />
      </div>
    </div>
  );
};

export default OfferSlide;
