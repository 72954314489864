import gql from 'graphql-tag';

const GET_OFFERS_BY_BLOCK_QUERY = gql`
  query GetOffersByBlockId($centerId: String!) {
    blockContentById(id: $centerId) {
      ... on BlockContentReferencesPromues {
        entityId
        entityLabel
          fieldContenuLien {
          url {
            path
          }
          uri
        }
        fieldContenuPromoLien {
          url {
            path
          }
          uri
        }
      }
    }
  }`;

export default GET_OFFERS_BY_BLOCK_QUERY;
