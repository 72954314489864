import gql from "graphql-tag";

const GET_CENTER_EVENTS_BY_ID = gql`
  query GetCenterEventsById($centerId: String!){
    blockContentById(id: $centerId) {
      ... on BlockContentReferencesSimples {
        entityId
        fieldTitre
        fieldContenuLien {
          uri
          url {
            path
          }
        }
        fieldLien {
          url {
            path
          }
          title
        }
      }
      
    }
  }
`;

export default GET_CENTER_EVENTS_BY_ID;
