import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { RightOutlined } from '@ant-design/icons';
import { string } from 'prop-types';

import CaliceoLink from 'components/buttons/CaliceoLink';

import { centreSimplePushBlock } from 'utils/constants';

import useWindowWidth from 'hooks/use-window-width';
import './simplePushBlock.scss';

const SimplePushBlock = (props) => {
  const { selectedCenter } = props;

  const SIMPLE_PUSH_BLOCK_QUERY = gql`
    query SimplePushBlockQuery($blockId: String!){
      blockContentById(id: $blockId) {
        ... on BlockContentPushButton {
          entityId
          entityLabel
          fieldActiveBloc
          fieldTitre
          fieldLien {
            title
            url {
              path
            }
          }
          fieldIcone {
            entity {
              ... on File {
                filename
                url
              }
            }
          }
        }
      }
    }
  `;

  const [getSimplePushBlock, simplePushBlockRes] = useLazyQuery(SIMPLE_PUSH_BLOCK_QUERY);

  useEffect(() => {
    if (selectedCenter) {
      getSimplePushBlock({
        variables: {
          blockId: centreSimplePushBlock[selectedCenter],
        },
      });
    }
  }, [selectedCenter]);

  const blockData = simplePushBlockRes.data?.blockContentById;

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  if (!simplePushBlockRes.data) {
    return null;
  }

  if (!blockData?.fieldActiveBloc) {
    return null;
  }

  if (windowWidth < 769) {
    return (
      <div className="container">
        <div className="simple-push-block--mobile">
          <div className="simple-push-block--body">
            <img className="simple-push-block--image" src={blockData.fieldIcone.entity.url} alt={blockData.fieldIcone.entity.title} />
            <div className="simple-push-block--mobile--content">
              <h1 className="simple-push-block--title">{blockData.fieldTitre}</h1>
              <p className="simple-push-block--subtitle">
                Découvrir nos offres dédiées
              </p>
            </div>
            <Link to="/offers" className="simple-push-block--mobile-link">
              <RightOutlined />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="simple-push-block--wrapper">
      <div className="container">
        <div className="simple-push-block--body">
          <img className="simple-push-block--image" src={blockData.fieldIcone.entity.url} alt={blockData.fieldIcone.entity.title} />
          <h1 className="simple-push-block--title">{blockData.fieldTitre}</h1>
          <CaliceoLink
            iconFill="#8bacdb"
            title={blockData.fieldLien.title}
            url={blockData.fieldLien.url.path}
            primaryButton={false}
            classNames="simple-push-block--link primary-caliceo--button--inverted"
          />
        </div>
      </div>
    </div>
  );
};

SimplePushBlock.defaultProps = {
  selectedCenter: 'home-group',
};

SimplePushBlock.propTypes = {
  selectedCenter: string,
};

export default SimplePushBlock;
