import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { getCenterById } from 'utils/getCentersBy';
import CaliceoLink from 'components/buttons/CaliceoLink';
import moment from 'moment';

import CENTER_ONE_TIME_MESSAGE_MODAL_QUERY from 'hooks/use-center-one-time-message-modal-content';

import './oneTimeMessageBlock.scss';

const OneTimeMessageModal = (props) => {
  const { selectedCenter, centers } = props;

  const [centerObject, setCenterObject] = useState(null);
  const [messageConditions, setMessageConditions] = useState(null);

  useEffect(() => {
    setCenterObject(getCenterById(centers, selectedCenter));
  }, [selectedCenter, centers]);

  const [getOneTimeMessage, oneTimeMessageRes] = useLazyQuery(CENTER_ONE_TIME_MESSAGE_MODAL_QUERY);

  const createMessageConditions = useCallback(
    () => {
      const universeConditions = [
        { field: 'type', value: 'popin' },
        (centerObject ? { field: 'field_relation_domain', value: `${centerObject?.domainAccess}` } : []),
      ];
      return universeConditions.flat();
    },
    [centerObject],
  );

  useEffect(() => {
    setMessageConditions(createMessageConditions());
  }, [createMessageConditions]);

  useEffect(() => {
    getOneTimeMessage({
      variables: {
        oneTimeMessageBlockConditions: messageConditions,
      },
    });
  }, [messageConditions]);

  const [showOneTimeModal, setShowOneTimeModal] = useState(false);

  const closeOneTimeModal = () => {
    sessionStorage.setItem('one-time-message-modal-shown', true);
    setShowOneTimeModal(false);
  };

  useEffect(() => {
    const today = new Date();
    const d_fin_mess = new Date(oneTimeMessageRes?.data?.blockContentQuery?.entities[0]?.fieldDateFin?.date);
    /*if(d_fin_mess > today) {
      console.log(d_fin_mess);
      console.log(today);
    }*/
    
    //console.log(oneTimeMessageRes?.data?.blockContentQuery?.entities[0]);
    
    if (
      oneTimeMessageRes?.data?.blockContentQuery?.entities[0]?.fieldActiveBloc
      && !sessionStorage.getItem('one-time-message-modal-shown')
    ) {
      //if(d_fin_mess < today) {
        
        setShowOneTimeModal(true);
        sessionStorage.setItem('one-time-message-modal-shown', true);
      //}
    }
  }, [oneTimeMessageRes]);

  const modalContent = oneTimeMessageRes?.data?.blockContentQuery.entities[0];

  if (!modalContent) {
    return null;
  }
  
  

  return (
    <Modal
      width={850}
      className="one-time-modal"
      visible={showOneTimeModal}
      footer={null}
      onCancel={closeOneTimeModal}
    >
      <div className="one-time-modal-image--wrapper">
        <img src={modalContent.fieldImage.entity.fieldMediaImage.url} className="one-time-modal-image" alt={modalContent.fieldTitre} />
      </div>
      <h1 className="one-time-modal--title">{modalContent.fieldTitre}</h1>
      <div className="one-time-modal--description" dangerouslySetInnerHTML={{ __html: modalContent.fieldDescription?.processed }} />
      <CaliceoLink
        primaryButton
        url={modalContent.fieldLien.url.path}
        title={modalContent.fieldLien.title}
        classNames="one-time-modal--button"
      />
    </Modal>
  );
};

export default OneTimeMessageModal;
