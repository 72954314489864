import graphql from 'graphql-tag';

const ONE_TIME_MESSAGE_BLOCK_QUERY = graphql`
  query OneTimeMessageBlock($oneTimeMessageBlockConditions: [EntityQueryFilterConditionInput]){
    blockContentQuery(
      filter: {
        conditions: $oneTimeMessageBlockConditions
        }
      ) {
      entities {
        ... on BlockContentPlanifie {
          entityId
          entityLabel
          fieldActiveBloc
          fieldTitre
          fieldDescription {
            processed
          }
          fieldLien {
            title
            url {
              path
            }
          }
          fieldDateDebut {
            date
          }
          fieldDateFin {
            date
          }
        }
      }
    }
  }
`;

//   return {
//     messageBlockTitle: caliceo.blockContentQuery.entities[0].fieldTitre,
//     messageBlockDesc: caliceo.blockContentQuery.entities[0].fieldDescription.processed,
//     messageBlockLink: {
//       url: caliceo.blockContentQuery.entities[0].fieldLien.url.path,
//       link: caliceo.blockContentQuery.entities[0].fieldLien.title,
//     },
//     messageBlockIsVisible: caliceo.blockContentQuery.entities[0].fieldActiveBloc,
//   }
// };

export default ONE_TIME_MESSAGE_BLOCK_QUERY;
