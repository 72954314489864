import React from 'react';

import CaliceoLink from 'components/buttons/CaliceoLink';

const UserCardUnauthorized = (props) => (
  <div className="not-authorized-user-block">
    <h2 className="not-authorized-user-block--title">
      Entrez dans la bulle Calicéo
    </h2>
    <p className="not-authorized-user-block--desc">
      C’est simple et gratuit
    </p>
    <p className="not-authorized-user-block--non-clickable">
      ACCÉDEZ À NOS EXCLUSIVITÉS
    </p>
    <p className="not-authorized-user-block--non-clickable">
      RECHARGEZ VOTRE COMPTE
    </p>
    <p className="not-authorized-user-block--non-clickable">
      RÉSERVEZ VOS SÉANCES
    </p>
    <p className="not-authorized-user-block--non-clickable">
      Recevez vos offres
    </p>
    <div className="custom-user-block--buttons-wrapper">
      <CaliceoLink
        url="/register"
        title="Créer un compte"
        primaryButton
        classNames="mr-0 mr-md-3"
      />
      <CaliceoLink
        url="/login"
        title="Se connecter"
        primaryButton={false}
        classNames="secondary-caliceo--button"
      />
    </div>
  </div>
);

export default UserCardUnauthorized;
