import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import useWindowWidth from 'hooks/use-window-width';

import CaliceoLink from 'components/buttons/CaliceoLink';
import CenterEventCard from './CenterEventCard';

const checkNumberOfEvents = (numOfEvents) => {
  if (numOfEvents === 3) {
    return 'three-items';
  }
  if (numOfEvents === 2) {
    return 'two-items';
  }
  return 'single-item';
};

const CenterEventsSection = (props) => {
  const { eventNodeIds, title, allEventsLink, allEventsTitle } = props;

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false,
  };

  if (windowWidth < 769) {
    return (
      <div className="center-events--events-wrapper">
        <h2 className="center-events--events-title">{title}</h2>
        <div className="cont_slider_events">
            {eventNodeIds.map((event) => (
              <CenterEventCard key={event} eventNodeId={event} selectedCenter={props.selectedCenter} />
            ))}
        </div>
      </div>
    );
  }


  return (
    <div className="center-events--events-wrapper">
      <h2 className="center-events--events-title">{title}</h2>
      <div className={`center-events--event-row ${checkNumberOfEvents(eventNodeIds.length)}`}>
        {eventNodeIds.map((event) => (
          <CenterEventCard key={event} eventNodeId={event} selectedCenter={props.selectedCenter} />
        ))}
      </div>
      <CaliceoLink
        url={allEventsLink}
        title={allEventsTitle}
        primaryButton
        classNames="all-event--button"
      />
    </div>
  );
};

export default CenterEventsSection;
