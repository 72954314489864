import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CaliceoLink from 'components/buttons/CaliceoLink';
import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';
import { centreEventsBlockIds } from 'utils/constants';

import qs from 'qs';
import API from 'api';

const CenterEventCard = (props) => {
  const { eventNodeId } = props;
  const cookies = new Cookies();

  const [urlImage, setUrlImage] = useState("");
  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");

  useEffect(() => {
    if(eventNodeId) {
      const postValues = {
      session: cookies.get(centerCookieIds[props.selectedCenter]?.cookieName),
      id: centreEventsBlockIds[props.selectedCenter],
    };
    const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
    API.get(`/centre-events-block?${qstring}`, { centerId: props.selectedCenter?.centerId }).then((res) => {
        
        if (res.data.status === 1) {
          let tabId = [];
          res.data.responseData.entities.forEach(e => {
            if(e.nid === eventNodeId) {
              setUrlImage(e.fieldAccrocheFondImage.url);
              setTitle(e.title);
              if(e.url.includes('aquaoOid')) {
                setPath("/produit"+e.url);
              }else {
                setPath(e.url);
              }
              
            }
          });
        }
      });
    }
    
  }, [eventNodeId]);

  
  return (
    <div className={"center-events--event "}>
      <img
        className="center-events--event-image"
        src={urlImage}
        alt={title}
      />
      <h2 className="center-events--event-title">{title}</h2>
      {/*<div className="center-events--event-date" dangerouslySetInnerHTML={{ __html: data?.nodeById?.fieldAccrocheTexte?.processed }} />*/}
      <CaliceoLink
        url={path}
        primaryButton
        classNames="center-events--event-link"
      />
    </div>
  );
};

export default CenterEventCard;
