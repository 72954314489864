import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLazyQuery } from '@apollo/react-hooks';

import { centreOffersBlockIds } from 'utils/constants';
import GET_OFFERS_BY_BLOCK_QUERY from 'hooks/use-center-offers-content';

import OfferSlide from 'components/home-group/our-offers/OfferSlide';
import CaliceoLink from 'components/buttons/CaliceoLink';

import OffersRightArrow from 'components/offer-slider-arrows/OffersRightArrow';
import OffersLeftArrow from 'components/offer-slider-arrows/OffersLeftArrow';

import useWindowWidth from 'hooks/use-window-width';
import 'components/home-group/our-offers/ourOffers.scss';
import './centerOffers.scss';


const CenterOffers = (props) => {
  const { selectedCenter } = props;
  const [windowWidth, setWindowWidth] = useState(0);
  const [offerUrls, setOfferUrls] = useState([]);

  useWindowWidth({ setWindowWidth });

  const [centerOffers, setCenterOffers] = useState([]);

  const getCenterOfferIds = (offer) => {
    const urlFragments = offer.offerUrl.split('/');
    const offerAquaoOidFragments = urlFragments[urlFragments.length - 1].split('?');
    const qs = queryString.parse(offerAquaoOidFragments[1]);
    const urlHasQuery = urlFragments[urlFragments.length - 1]?.includes('?');
    return ({
      offerUrl: offer.offerUrl,
      offerNid: urlHasQuery ? offerAquaoOidFragments[0] : urlFragments[urlFragments.length - 1],
      offerAquaoOid: urlHasQuery ? qs : '',
      promotedOffer: offer.promotedOffer,
      offerAlias: offer.offerAlias,
    });
  };

  const [getOffers, offersRes] = useLazyQuery(GET_OFFERS_BY_BLOCK_QUERY);

  useEffect(() => {
    if (selectedCenter) {
      getOffers({
        variables: {
          centerId: centreOffersBlockIds[selectedCenter],
        },
      });
    }
  }, [selectedCenter]);

  useEffect(() => {
    if (offersRes.data) {
      const regularOffers = offersRes.data?.blockContentById
        .fieldContenuLien.map((regularOffer) => ({
          offerUrl: regularOffer?.uri,
          promotedOffer: false,
          offerAlias: regularOffer?.url.path,
        }));

      const promoOffers = offersRes.data?.blockContentById
        .fieldContenuPromoLien.map((promoOffer) => ({
          offerUrl: promoOffer?.uri,
          promotedOffer: true,
          offerAlias: promoOffer?.url.path,
        }));

      let index = 2;

      Array.from(promoOffers).forEach((v) => {
        regularOffers.splice(index, 0, v);
        index += 2;
      });

      setOfferUrls(regularOffers);
    }
  }, [offersRes.data?.blockContentById]);

  useEffect(() => {
    const slicedNodeIds = offerUrls.map((offer) => getCenterOfferIds(offer));
    setCenterOffers(slicedNodeIds);
  }, [offerUrls]);

  const desktopSettings = (numOfOffers) => ({
    dots: false,
    infinite: false,
    speed: 500,
    rows: (numOfOffers > 6) ? 2 : 1,
    slidesToShow: 3,
    nextArrow: <OffersRightArrow />,
    prevArrow: <OffersLeftArrow />,
  });

  const mobileSettings = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
    infinite: false,
    dots: false,
    speed: 500,
    autoPlay: true,

  };

  


  

  const handleCenterOfferClick = (e, selectedVariantOid, selectedOffer) => {
    if (selectedOffer.fieldAquaoOid) {
      return navigate(`/produit${selectedOffer.path.alias}?aquaoOid=${selectedOffer.fieldAquaoOid}`);
    }
    return navigate(`/produit${selectedOffer.path.alias}`);
  };

  if (!offersRes.data) {
    return null;
  }

  return (
    <div className="container">
      <h2 className="center-offers--title">Nos offres incontournables</h2>
      <p className="center-offers--subtitle">Découvrir nos offres les plus appréciées</p>
      {windowWidth > 768 ? (
        <Slider {...desktopSettings(centerOffers.length)} className="offers-slider center-offers-slider">
          {centerOffers.map((offer, i) => (
            <OfferSlide
              key={`${offer.offerUrl}-${i}`}
              offer={offer}
              isPromo={offer.promotedOffer}
              handleOfferClick={handleCenterOfferClick}
            />
          ))}
        </Slider>
      ) : (
        <>
        
        <div className="center-events--events-wrapper">
          <div className="cont_slider_events slider_android">
              {centerOffers.map((offer) => (
                <div className="center-events--event">
                  <OfferSlide
                    key={offer.offerUrl}
                    offer={offer}
                    isPromo={offer.promotedOffer}
                    handleOfferClick={handleCenterOfferClick}
                  />
                </div>
                
              ))}
          </div>
        </div>
        
        </>
      )}
      <CaliceoLink
        primaryButton
        classNames="all-offers-button"
        title="Découvrir toutes nos offres"
        url="/offers"
      />
    </div>
  );
};

export default CenterOffers;
