import React, { useEffect } from 'react';
import { centerCookieIds } from 'utils/constants';
import { Link } from 'gatsby';
import CaliceoLink from 'components/buttons/CaliceoLink';
import RightArrow from 'components/icon-components/RightArrow';

const UserCardAuthorized = (props) => {
  const { actions, userInfo, centers, selectedCenter } = props;

  useEffect(() => {
    actions.getAccountInfo(
      localStorage.getItem(centerCookieIds[selectedCenter].cookieName),
      selectedCenter,
    );
  }, []);

  return (
    <div className="not-authorized-user-block">
      <h2 className="not-authorized-user-block--title authorized-title">
        Bienvenue {userInfo?.firstname} {userInfo?.lastname}
      </h2>
      <div className="user-links-block">
        <Link to="/mon-compte" className="not-authorized-user-block--non-clickable">
          Consulter mes commandes
          <RightArrow fill="#414141" />
        </Link>
        <Link to="/mon-compte" className="not-authorized-user-block--non-clickable">
          Voir mes bons cadeaux
          <RightArrow fill="#414141" />
        </Link>
        <Link to="/mon-compte" className="not-authorized-user-block--non-clickable">
          M’abonner aux offres Calicéo
          <RightArrow fill="#414141" />
        </Link>
        <Link to="/mon-compte" className="not-authorized-user-block--non-clickable">
          Modifier mes données personnelles
          <RightArrow fill="#414141" />
        </Link>
      </div>
      <div className="custom-user-block--buttons-wrapper">
        <CaliceoLink
          url="/mon-compte"
          title="Aller sur mon espace"
          primaryButton
          classNames="mr-0 mr-md-3"
        />
      </div>
    </div>
  );
};

export default UserCardAuthorized;
