import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Select } from 'antd';
import CaliceoButton from 'components/buttons/CaliceoButton';
import useGetCenters from 'hooks/use-get-centers';
import './researchModule.scss';

const { Option } = Select;

import { getCenterName, getHostname } from 'utils/location';

import forwardToCenter from 'utils/forwardToCenter';
import GuideGiftModal from '../guide-gift-modal/GuideGiftModal';

/* const offerOptions = [
  {
    key: 'Bains',
    value: 'un accès aux bains',
  },
  {
    key: 'Vitalité',
    value: 'une séance vitalité',
  },
  {
    key: 'Massage',
    value: 'un soin ou un massage',
  },
  {
    key: 'Cadeau',
    value: 'offrir un cadeau',
  },
  {
    key: 'allOffers',
    value: 'toutes nos offres',
  },
]; */

const offerOptions = [
  {
    key: 'Bains',
    value: 'un accès aux bains',
  },
  {
    key: 'Vitalité',
    value: 'une séance vitalité',
  },
  {
    key: 'Massage',
    value: 'un soin ou un massage',
  },
  {
    key: 'Cadeau',
    value: 'offrir un cadeau',
  },
  {
    key: 'allOffers',
    value: 'toutes nos offres',
  },
];

const ResearchModule = (props) => {
  const centers = useGetCenters();

  const centerName = getCenterName(props.location);
  const hostname = getHostname(props.location);
  const buttonClass = props.buttonClass ? props.buttonClass : null;

  const [product, setProduct] = useState('allOffers');
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [displayedOffers, setDisplayedOffers] = useState(offerOptions);
  const [displayedCenters, setDisplayedCenters] = useState(centers);
  const [giftModalShown, setGiftModalShown] = useState(false);

  const forwardTo = (_center, url) => {
    if (`centre-${centerName}` === _center) {
      navigate(url);
    } else {
      forwardToCenter(centers, _center, hostname, url);
    }
  };

  const filteredOffers = displayedOffers;

  const handleChangeOffer = (val) => {
    const filteredCenters = displayedCenters.filter((center) => center.centerId !== 'centre-bordeaux');
    setProduct(val);
    setDisplayedCenters(val === 'Massage' ? filteredCenters : centers);
  };

  const handleChangeCity = (val) => {
    setSelectedCenter(val);
    setDisplayedOffers(val === 'centre-bordeaux' ? filteredOffers : offerOptions);
  };

  useEffect(() => {
    setSelectedCenter(`${(centerName === 'localhost' || centerName === 'caliceo') ? 'sélectionnez votre centre' : `centre-${centerName}`}`);
    if (centerName === 'bordeaux') {
      setDisplayedOffers(filteredOffers);
    }
  }, [centerName]);

  const navigateToSelection = (center, prod) => {
    if (center === 'sélectionnez votre centre') return;
    /* if (prod === 'Cadeau' && !giftModalShown) {
      setGiftModalShown(true);
      return;
    } */
    if (prod === 'allOffers' || prod === 'Cadeau') {
      forwardTo(center, '/offers');
    } else if (prod === 'Massage') {
      forwardTo(center, '/offers/SPA');
    } else {
      forwardTo(center, `/offers/${prod}`);
    }
  };

  return (
    <div className="research-module--wrapper">
      <div>
        <span className="research-module--text research-module--mobile ">Je souhaite</span>
        <Select
          size="large"
          onChange={handleChangeOffer}
          defaultValue="allOffers"
          className="research--center-selection"
          dropdownAlign={{ offset: [0, 0] }}
          dropdownClassName="research--center-selection--dropdown"
        >
          {displayedOffers.map((offer) => (
            <Option key={offer.key} value={offer.key}>
              {offer.value}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <span className="research-module--text">dans le centre de</span>
        <Select
          size="large"
          onChange={handleChangeCity}
          defaultValue={selectedCenter || 'sélectionnez votre centre'}
          value={selectedCenter || 'sélectionnez votre centre'}
          className="research--center-selection"
          dropdownAlign={{ offset: [0, 0] }}
          dropdownClassName="research--center-selection--dropdown"
        >
          {displayedCenters.map((center) => {
            // console.log(center.centerId);
            if (center.centerId !== 'centre-paris') {
              return <Option key={center.centerId} value={center.centerId}>{center.name}</Option>;
            }
            return null;
          })}
        </Select>
        {buttonClass != null
          ? (
            <CaliceoButton
              primaryButton={false}
              classNames="secondary-caliceo--button research-module--btn"
              onClick={() => navigateToSelection(selectedCenter, product)}
              title="Rechercher"
              iconFill="#ffffff"
            />
          )
          : (
            <CaliceoButton
              primaryButton
              classNames="research-module--btn"
              onClick={() => navigateToSelection(selectedCenter, product)}
              title="Rechercher"
              iconFill="#ffffff"
            />
          )}

        {giftModalShown && (
          <GuideGiftModal
            setGiftModalShown={setGiftModalShown}
            giftModalShown={giftModalShown}
            selectedCenter={selectedCenter}
            displayedCenters={displayedCenters}
            centers={centers}
          />
        )}
      </div>
    </div>
  );
};

export default ResearchModule;
