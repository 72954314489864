import React, { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import CaliceoLink from 'components/buttons/CaliceoLink';

import ONE_TIME_MESSAGE_BLOCK_QUERY from 'hooks/use-one-time-message-block-content';
import { getCenterById } from 'utils/getCentersBy';

import '../oneTimeMessageBlock.scss';


const OneTimeMessageBlock = (props) => {
  const { centers, selectedCenter } = props;

  const [centerObject, setCenterObject] = useState(null);
  const [messageConditions, setMessageConditions] = useState(null);
  const [message_hide, setMessageHide] = useState(false);

  useEffect(() => {
    setCenterObject(getCenterById(centers, selectedCenter));
  }, [selectedCenter, centers]);

  const [getOneTimeMessage, oneTimeMessageRes] = useLazyQuery(ONE_TIME_MESSAGE_BLOCK_QUERY);

  const createMessageConditions = useCallback(
    () => {
      const universeConditions = [
        { field: 'type', value: 'planifie' },
        (centerObject ? { field: 'field_relation_domain', value: `${centerObject?.domainAccess}` } : []),
      ];
      return universeConditions.flat();
    },
    [centerObject],
  );

  useEffect(() => {
    setMessageConditions(createMessageConditions());
  }, [createMessageConditions]);

  useEffect(() => {
    getOneTimeMessage({
      variables: {
        oneTimeMessageBlockConditions: messageConditions,
      },
    });
  }, [messageConditions]);
  
  useEffect(() => {
    const today = new Date();
    const d_fin_mess = new Date(oneTimeMessageRes?.data?.blockContentQuery?.entities[0]?.fieldDateFin?.date);
    if(d_fin_mess < today) {
      setMessageHide(true);
    }
  }, [oneTimeMessageRes]);

  if (!oneTimeMessageRes.data?.blockContentQuery.entities[0]) {
    return null;
  }

  if (oneTimeMessageRes.data?.blockContentQuery.entities[0]?.fieldActiveBlock) {
    return null;
  }

  return (
    <div>
    <div className={"container one-time-message--block "+(message_hide ? 'hide' : '')}>
      <h2 className="one-time-message--block-title">{oneTimeMessageRes.data?.blockContentQuery.entities[0].fieldTitre}</h2>
      <div className="one-time-message--block-desc" dangerouslySetInnerHTML={{ __html: oneTimeMessageRes.data?.blockContentQuery.entities[0].fieldDescription?.processed }} />
      <CaliceoLink
        url={oneTimeMessageRes.data?.blockContentQuery.entities[0].fieldLien.url.path}
        primaryButton
        title={oneTimeMessageRes.data?.blockContentQuery.entities[0].fieldLien.title}
        classNames="one-time-message--block-link"
      />
    </div>
    <div className={"espa_zone_sans_mess "+(!message_hide ? 'hide' : '')}></div>
    </div>
  );
};

export default OneTimeMessageBlock;
