import React,{ useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import CENTER_PRESENTATION_QUERY from 'hooks/use-center-presentation-content';

import CaliceoLink from 'components/buttons/CaliceoLink';

import FBBlueIcon from 'images/icons/RS/FB-blue.svg';
import LinkedinBlueIcon from 'images/icons/RS/linkedin-blue.svg';
import IGBlueIcon from 'images/icons/RS/Instagram-blue.svg';

import logo_jauge from 'images/caliceo-jauge-grey.svg';
import logo_jauge_act from 'images/caliceo-jauge-bleu.svg';

import qs from 'qs';
import API from 'api';
import { centerCookieIds } from 'utils/constants';

import './centerPresentation.scss';

const CenterPresentation = (props) => {
  const { selectedCenter } = props;

  const { data, loading, error} = useQuery(CENTER_PRESENTATION_QUERY, {
    variables: {
      centerId: selectedCenter,
    },
  });

  const centerData = data?.nodeQuery.entities[0];
  const [FmiRes, setFmiRes] = useState('');
  
  if (!centerData) {
    return null;
  }

  const centerId = centerCookieIds[selectedCenter].cid;
  //console.log(centerId);
  API.post('/khfugn?cid=' + centerId).then((res) => {
    if (res.data.status === 1) {
      setFmiRes(res.data.responseData.FMI[0]);
    }
  });
  
  return (
    <div className="container center-presentation--section">
      {centerData?.fieldAfficherJauge === true && (
        <div className="jauge">
          <a href={centerData.fieldUrlJaugeFrequentation?.url?.path} className="lien_jauge">
          <p className="titre_aff">Affluence en direct</p>
          <div className="cont_img_jauge">
            <img src={logo_jauge} className="img_jauge" />
            <div className="cont_img_jauge_act" style={{height:FmiRes}}>
              <img src={logo_jauge_act} className="img_jauge_act" />
            </div>
          </div>
          <p className="pourc_aff">{FmiRes}</p>
          </a>
        </div>
      )}

      
      <div className="center-presentation--wrapper">
        <div className="center-presentation-image--wrapper">
          <img
            src={centerData.fieldPresentationVisuel?.entity.fieldMediaImage.url}
            alt={centerData.fieldPresentationTitre}
            className="center-presentation-image"
          />
          {centerData?.fieldAfficherTemperature === true && (
            <div className="center-presentation--temp-block">
              <p className="temp-block--text">
                l’eau est à
                <span className="temp-block--temp"> {centerData.fieldTemperatureEau}°C</span>
              </p>
            </div>
          )}
          
        </div>
        <div className="center-presentation--content">
          <h2 className="center-presentation--title">{centerData.fieldPresentationTitre}</h2>
          <p className="center-presentation--subtitle">{centerData.fieldPresentationSoustitre}</p>
          <div className="center-presentation--description" dangerouslySetInnerHTML={{ __html: centerData.fieldPresentationDescription?.processed }} />
          <div className="center-presesentation--socials--wrapper">
            <a className="mr-3" href={centerData?.fieldReseauxSociaux[0]?.url?.path} target="_blank" rel="noopener noreferrer">
              <img src={FBBlueIcon} alt={`${centerData?.fieldPresentationTitre} facebook`} className="social-link--icons" />
            </a>
            <a className="mr-3" href={centerData?.fieldReseauxSociaux[1]?.url?.path} target="_blank" rel="noopener noreferrer">
              <img src={IGBlueIcon} alt={`${centerData?.fieldPresentationTitre} instagram`} className="social-link--icons" />
            </a>
            
            {centerData?.fieldReseauxSociaux[2]?.url?.path && (
              <a href={centerData?.fieldReseauxSociaux[2]?.url?.path} target="_blank" rel="noopener noreferrer">
                <img src={LinkedinBlueIcon} alt={`${centerData?.fieldPresentationTitre} linkedin`} className="social-link--icons" />
              </a>
            )}

          </div>
          <div className="center-presentation--buttons-wrapper">
            <CaliceoLink
              primaryButton
              url={centerData.fieldPresentationCentreLink?.entity.entityUrl.path}
              title="Découvrir le centre"
              classNames="mr-0 mr-md-3"
            />
            <CaliceoLink
              primaryButton={false}
              url={centerData.fieldPresentationTarifsLink?.entity.entityUrl.path}
              title="Brochure et tarifs"
              classNames="secondary-caliceo--button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterPresentation;
